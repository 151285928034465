@media screen and (min-width: 900px) {
  .service::after {
    top: 30%;
  }
}

.animateText {
  animation: scroll 16s linear infinite;
  animation-delay: 2s;
}

/* @keyframes scroll {
  0%,
  12.5% {
    transform: translateY(-0%);
    -webkit-transform: translateY(-0%);
    -ms-transform: translateY(-0%);
    -o-transform: translateY(-0%);
    -moz-transform: translateY(-0%);
    opacity: 1;
  }
  13%,
  25% {
    transform: translateY(-12.5%);
    -webkit-transform: translateY(-12.5%);
    -ms-transform: translateY(-12.5%);
    -o-transform: translateY(-12.5%);
    -moz-transform: translateY(-12.5%);
  }
  26%,
  37.5% {
    transform: translateY(-25%);
    -webkit-transform: translateY(-25%);
    -ms-transform: translateY(-25%);
    -o-transform: translateY(-25%);
    -moz-transform: translateY(-25%);
  }
  39%,
  50% {
    transform: translateY(-37.9%);
    -webkit-transform: translateY(-37.9%);
    -ms-transform: translateY(-37.9%);
    -o-transform: translateY(-37.9%);
    -moz-transform: translateY(-37.9%);
  }
  52%,
  62.5% {
    transform: translateY(-50.6%);
    -webkit-transform: translateY(-50.6%);
    -ms-transform: translateY(-50.6%);
    -o-transform: translateY(-50.6%);
    -moz-transform: translateY(-50.6%);
  }

  63%,
  75% {
    transform: translateY(-62.9%);
    -webkit-transform: translateY(-62.9%);
    -ms-transform: translateY(-62.9%);
    -o-transform: translateY(-62.9%);
    -moz-transform: translateY(-62.9%);
  }

  76%,
  87.5% {
    transform: translateY(-75.6%);
    -webkit-transform: translateY(-75.6%);
    -ms-transform: translateY(-75.6%);
    -o-transform: translateY(-75.6%);
    -moz-transform: translateY(-75.6%);
  }

  89%,
  99.5% {
    transform: translateY(-88%);
    -webkit-transform: translateY(-88%);
    -ms-transform: translateY(-88%);
    -o-transform: translateY(-88%);
    -moz-transform: translateY(-88%);
  }
} */



@keyframes scroll {
  0%, 11.11% {
    transform: translateY(-0%);
    opacity: 1;
  }
  12.22%, 23.33% {
    transform: translateY(-12.5%);
  }
  24.44%, 35.56% {
    transform: translateY(-25%);
  }
  36.67%, 47.78% {
    transform: translateY(-37.9%);
  }
  48.89%, 60% {
    transform: translateY(-50.6%);
  }
  61.11%, 72.22% {
    transform: translateY(-62.9%);
  }
  73.33%, 84.44% {
    transform: translateY(-75.6%);
  }
  85.56%, 96.67% {
    transform: translateY(-88%);
  }
  97.78%, 100% {
    transform: translateY(-88%);
  }
}





.scrollText {
  animation: disappear 2s ease infinite;
  animation-delay: 2.1s;
}

/* @keyframes disappear {
  0% {
    opacity: 0;
  }
  12.5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  98.5% {
    opacity: 0;
  }
} */

.movingText {
  animation: slide 16s linear infinite;
}

@keyframes slide {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-49%);
  }
}

.bounceForward {
  animation: bounce 1s infinite;
}

@keyframes bounceForward {
  0%,
  100% {
    transform: translateX(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.Toastify__toast-container {
  position: fixed;
  top: 12vh;
  z-index: 1000;
}
