
@media screen and (min-width: 1025px) {
  
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .logo {
    width: 200px;
  }
  
  .header-title {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    color: white;
    text-align: center;
  }
  
  .header-desc {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: white;
    margin-top: -40px;
    text-align: center;
  }
  
  
  .section-0 {
    display: flex;
    flex-direction: column;
    width: 830px;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin-top: 50px;
    background: #F5F0D2;
    padding: 20px 40px;
    border-radius: 12px;
  }
  
  .section-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin-top: 130px;
  }
  
  .onboarding-form {
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .input-field-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;
  }
  
  .input-label {
    font-style: normal;
    font-weight: bolder;
    font-size: 12px;
  }
  
  .input-field-err-msg {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #FF0000;
    margin-left: 20px;
  }
  
  
  .input-field {
    width: 100%;
    height: 100%;
    border: 0px;  
    background: rgba(0, 0, 0, 0.0);
    font-size: 15px;
    font-weight: 400;
    /* background: #FAFAFA; */
    /* background-color: #016AEC; */
  }
  
  .input-field-box {
    width: 360px;
    height: 40px;
    background: #F5F0D2;
    /* background-color: blue; */
    padding: 3px 10px;;
    border-radius: 6px;
    border: 1px solid grey;
  }
  
  input:focus {
    outline: none;
    /* Add any other styles you want for the focused state */
  }
  
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    /* Add any other styles you want for autofill */
  }
  
  select:focus {
    outline: none;
    /* Add any other styles you want for the focused state */
  }
  
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    /* Add any other styles you want for autofill */
  }
  
  .input-field-2 {
    width: 100%;
    height: 100%;
    border: 0px;
    outline: none;
    background: rgba(0, 0, 0, 0.0);
  }
  
  .input-field-2:focus {
    outline: none;
  }
  
  .input-field-err {
    border: 1px solid #FF0000;
    width: 360px;
    height: 40px;
    background: #FAFAFA;
    padding: 0px 10px;
    margin-right: 50px; 
  }
  
  .input-field-box-err {
    border: 1px solid #FF0000;
    width: 360px;
    height: 40px;
    background: #F5F0D2;
    /* background-color: blue; */
    padding: 3px 10px;;
    border-radius: 6px;
  }
  
  .street-suggestions {
    background: #F1ECC7;
    padding: 0px 10px 10px 10px;
    height: 140px; /* Set the desired height for your container */  
    width: 360px;
    overflow: hidden; /* Hide any content that overflows the container */
    position: absolute;
    z-index: 1000;

  }
  
  .street-suggestions-contents {
    height: 100%;
    overflow-y: scroll; /* Enable vertical scrolling for the content */
  }
  
  .suggestion-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
    background: #F1ECC7;
    z-index: 100;
  }
  
  .suggestion-item p {
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  /* Apply the overlay to the target element */
  .suggestion-item:hover {
    background-color: #D5D1B7;
  }
  
  .is-existing-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 360px;
    margin-top: 10px;
  }
  
  .is-existing-group > p {
    font-size: 13px;
  }
  
  .primary-btn {
    
    background-color: #B4EA44;
    width: 350px;
    height: 45px;  
    border-radius: 10px;
    border: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: 'row';
    align-items: center;
    justify-content: center;
    align-self: center;
    color: black;
    font-size: 18px;
    /* font-family: Sora Variable; */
    font-weight: 600;
  }
  
  .primary-btn:not([disabled]):active {
    background-color: #94C62D;
    transform: translateY(2px);
  }
  
  button[disabled] {
    opacity: 0.9;
    cursor: not-allowed;
  }
  
  .primary-btn img {
    width: 20px;
    height: 15px;
    margin-left: 16px;
  }
  
  .secondary-btn {
    
    background-color: #F3F3F3;
    width: 180px;
    height: 45px;  
    background: #F3F3F3;
    border-radius: 45px;
    border: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: 'row';
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 18px;
    /* font-family: Sora Variable; */
    font-weight: 500;
    margin-right: 35px;
  }
  
  .secondary-btn img {
    width: 20px;
    height: 15px;
    margin-right: 16px;
  }
  
  .btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  
  }
  
  .banner-1 {
    height: 500px
  }
  
  .form-summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .summary-item {
    width: 540px;
  }
  
  .summary-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .summary-item-header-title {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }
  
  .summary-item-header-btn {
    background-color: rgba(0, 0, 0, 0.0);
    border: 0px;
    color: #016AEC;
    text-decoration: underline;
    cursor: pointer;
    /* font-family: Sora Variable; */
    font-size: 16px;
  }
  
  .summary-item-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    width: 540px;
    max-width: 540px;
    /* height: 350px; */
    padding: 25px;
    box-sizing: border-box;
  }
  
  .summary-item-group {
    display: flex;
    flex-direction: row;  
    width: 480px,
  }
  
  .summary-item-group-child {
    flex-basis: 50%;
  }
  
  .summary-item-group-child-label {
    font-size: 12px;
    color: #000000;
  }
  
  .summary-item-group-child-value {
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    margin-top: -4px;
  }
  
  .meter-integration-section {
    margin-top: 50px;
  }
  
  .meter-integration-title {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }
  
  .meter-integration-sub-title {
    font-weight: 600;
    /* font-family: Sora Variable; */
    font-style: normal;
    font-size: 14px;
    color: #454545;
    margin-top: -4px;
    width: 600px;
    margin-bottom: 20px;
  }
  
  .meter-integration-section img {
    /* height: 328px; */
    width: 1377px;
    margin-top: -30px;
  }
  
  .meter-selection-group {
    display: flex;
    flex-direction: row;
    align-items: center;  
    margin-top: -10px;
  }
  
  .meter-selection-group > p {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-left: 10px;
    color: #000;
  }
  
  .modal-contents {
    background: #FFFFFF;
    border-radius: 15px;
    width: 500px;
    /* height: 380px; */
    margin-top: 250px;
    padding: 20px;
    box-sizing: border-box;
    z-index: 999999999,
  }
  
  .close-btn-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .close-btn-group img {
    cursor: pointer;
    width: 24px;
  }
  
  .modal-title {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    margin-left: 20px;
  }
  
  .modal-sub-title {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: -10px;
  }
  
  textarea {
    resize: none;
  }
  
  .modal-btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .modal-btn-group-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 80px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  /* .modal-btn-group button {
    border: 0px;
    border-radius: 40px;
    height: 40px;
    min-width: 120px;
    padding: 0px 15px;
  } */
  
  .modal-secondary-btn {
    background: #D5D1B7;
    border: 0px;
    border-radius: 40px;
    height: 40px;
    min-width: 120px;
    padding: 0px 15px;
    font-weight: 600;
    cursor: pointer;
  }
  
  .modal-primary-btn {
    background-color: #B4EA44;
    color: black;
    border: 0px;
    border-radius: 40px;
    height: 40px;
    min-width: 120px;
    font-weight: 600;
    padding: 0px 15px;
    cursor: pointer;
  }
  
  .modal-primary-btn:active {
    background-color: #94C62D;
    transform: translateY(2px);
  } 
  
  .terms-section {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 360px;
  }
  
  .terms-section p {
    color: #000;
    font-size: 12px;
    margin-left: 10px;
    font-weight: 500;
  }
  
  
  .section-1 {
    display: flex;
    flex-direction: column;
    margin-top: 130px;
    width: 650px;
  }
  
  .success-icon {
    width: 180px;
  }
  
  .section-1 > p {
    color: #FFF;
    text-align: center;
    font-family: 'Sora';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 80px;
  }
  
  .section-1 > a {
    
    background-color: #B4EA44;
    width: 350px;
    height: 45px;  
    border-radius: 10px;
    border: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: 'row';
    align-items: center;
    justify-content: center;
    align-self: center;
    color: black;
    font-size: 18px;
    margin-top: 120px;
    font-weight: 600;
    text-decoration: none;
  }
  
  .section-1 > a:not([disabled]):active {
    background-color: #94C62D;
    transform: translateY(2px);
  }
  
  .fab {
    position: fixed;
    right: 20px;
    bottom: 20px
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  
  
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  
  .logo {
    width: 150px;
  }
  
  .header-title {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: white;
    text-align: center;
  }
  
  .header-desc {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: white;
    margin-top: -20px;
    text-align: center;
  }
  
  
  .section-0 {
    display: flex;
    flex-direction: column;
    width: 400px;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin-top: 50px;
    background: #F5F0D2;
    padding: 20px;
    border-radius: 12px;
  }
  
  .section-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin-top: 130px;
  }
  
  .onboarding-form {
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }


  
  .input-label {
    font-style: normal;
    font-weight: bolder;
    font-size: 12px;
  }
  
  .input-field-err-msg {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #FF0000;
    margin-left: 20px;
  }
  
  .input-span {
    margin-top: 10px;
  }
  
  .input-field {
    width: 100%;
    height: 100%;
    border: 0px;  
    background: rgba(0, 0, 0, 0.0);
    font-size: 15px;
    font-weight: 400;
    /* background: #FAFAFA; */
    /* background-color: #016AEC; */
  }
  
  .input-field-box {
    width: 360px;
    height: 40px;
    background: #F5F0D2;
    /* background-color: blue; */
    padding: 3px 10px;;
    border-radius: 6px;
    border: 1px solid grey;
  }
  
  input:focus {
    outline: none;
    /* Add any other styles you want for the focused state */
  }
  
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    /* Add any other styles you want for autofill */
  }
  
  select:focus {
    outline: none;
    /* Add any other styles you want for the focused state */
  }
  
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    /* Add any other styles you want for autofill */
  }
  
  .input-field-2 {
    width: 100%;
    height: 100%;
    border: 0px;
    outline: none;
    background: rgba(0, 0, 0, 0.0);
  }
  
  .input-field-2:focus {
    outline: none;
  }
  
  .input-field-err {
    border: 1px solid #FF0000;
    width: 360px;
    height: 40px;
    background: #FAFAFA;
    padding: 0px 10px;
    margin-right: 50px; 
  }
  
  .input-field-box-err {
    border: 1px solid #FF0000;
    width: 360px;
    height: 40px;
    background: #F5F0D2;
    /* background-color: blue; */
    padding: 3px 10px;;
    border-radius: 6px;
  }
  
  .street-suggestions {
    background: #F1ECC7;
    padding: 0px 10px 10px 10px;
    height: 140px; /* Set the desired height for your container */  
    width: 360px;
    overflow: hidden; /* Hide any content that overflows the container */
    position: absolute;
    z-index: 1000;
  }
  
  .street-suggestions-contents {
    height: 100%;
    overflow-y: scroll; /* Enable vertical scrolling for the content */
  }
  
  
  .suggestion-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
    background: #F5F0D2;
    z-index: 100;
  }
  
  .suggestion-item p {
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  /* Apply the overlay to the target element */
  .suggestion-item:hover {
    background-color: #E8E8E8;
  }
  
  .is-existing-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 360px;
    margin-top: 10px;
  }
  
  .is-existing-group > p {
    font-size: 13px;
  }
  
  .primary-btn {
    
    background-color: #B4EA44;
    width: 350px;
    height: 45px;  
    border-radius: 10px;
    border: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: 'row';
    align-items: center;
    justify-content: center;
    align-self: center;
    color: black;
    font-size: 18px;
    /* font-family: Sora Variable; */
    font-weight: 600;
  }
  
  .primary-btn:not([disabled]):active {
    background-color: #94C62D;
    transform: translateY(2px);
  }
  
  button[disabled] {
    opacity: 0.9;
    cursor: not-allowed;
  }
  
  .primary-btn img {
    width: 20px;
    height: 15px;
    margin-left: 16px;
  }
  
  .secondary-btn {
    
    background-color: #F3F3F3;
    width: 180px;
    height: 45px;  
    background: #F3F3F3;
    border-radius: 45px;
    border: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: 'row';
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 18px;
    /* font-family: Sora Variable; */
    font-weight: 500;
    margin-right: 35px;
  }
  
  .secondary-btn img {
    width: 20px;
    height: 15px;
    margin-right: 16px;
  }
  
  .btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  
  }
  
  .banner-1 {
    height: 500px
  }
  
  .form-summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .summary-item {
    width: 540px;
  }
  
  .summary-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .summary-item-header-title {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }
  
  .summary-item-header-btn {
    background-color: rgba(0, 0, 0, 0.0);
    border: 0px;
    color: #016AEC;
    text-decoration: underline;
    cursor: pointer;
    /* font-family: Sora Variable; */
    font-size: 16px;
  }
  
  .summary-item-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    width: 540px;
    max-width: 540px;
    /* height: 350px; */
    padding: 25px;
    box-sizing: border-box;
  }
  
  .summary-item-group {
    display: flex;
    flex-direction: row;  
    width: 480px,
  }
  
  .summary-item-group-child {
    flex-basis: 50%;
  }
  
  .summary-item-group-child-label {
    font-size: 12px;
    color: #000000;
  }
  
  .summary-item-group-child-value {
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    margin-top: -4px;
  }
  
  .meter-integration-section {
    margin-top: 50px;
  }
  
  .meter-integration-title {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }
  
  .meter-integration-sub-title {
    font-weight: 600;
    /* font-family: Sora Variable; */
    font-style: normal;
    font-size: 14px;
    color: #454545;
    margin-top: -4px;
    width: 600px;
    margin-bottom: 20px;
  }
  
  .meter-integration-section img {
    /* height: 328px; */
    width: 1377px;
    margin-top: -30px;
  }
  
  .meter-selection-group {
    display: flex;
    flex-direction: row;
    align-items: center;  
    margin-top: -10px;
  }
  
  .meter-selection-group > p {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-left: 10px;
    color: #000;
  }
  
  .modal-contents {
    background: #F5F0D2;
    border-radius: 15px;
    width: 500px;
    /* height: 380px; */
    margin-top: 250px;
    padding: 20px;
    box-sizing: border-box;
    z-index: 999999999,
  }
  
  .close-btn-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .close-btn-group img {
    cursor: pointer;
    width: 24px;
  }
  
  .modal-title {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    margin-left: 20px;
  }
  
  .modal-sub-title {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: -10px;
  }
  
  textarea {
    resize: none;
  }
  
  .modal-btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .modal-btn-group-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 80px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  /* .modal-btn-group button {
    border: 0px;
    border-radius: 40px;
    height: 40px;
    min-width: 120px;
    padding: 0px 15px;
  } */
  
  .modal-secondary-btn {
    background: #D5D1B7;
    border: 0px;
    border-radius: 40px;
    height: 40px;
    min-width: 120px;
    padding: 0px 15px;
    font-weight: 600;
    cursor: pointer;
  }
  
  .modal-primary-btn {
    background-color: #B4EA44;
    color: black;
    border: 0px;
    border-radius: 40px;
    height: 40px;
    min-width: 120px;
    font-weight: 600;
    padding: 0px 15px;
    cursor: pointer;
  }
  
  .modal-primary-btn:active {
    background-color: #94C62D;
    transform: translateY(2px);
  } 
  
  .terms-section {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 360px;
  }
  
  .terms-section p {
    color: #000;
    font-size: 12px;
    margin-left: 10px;
    font-weight: 500;
  }
  
  
  .section-1 {
    display: flex;
    flex-direction: column;
    margin-top: 130px;
    width: 650px;
  }
  
  .success-icon {
    width: 180px;
  }
  
  .section-1 > p {
    color: #FFF;
    text-align: center;
    font-family: 'Sora';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 80px;
  }
  
  .section-1 > a {
    
    background-color: #B4EA44;
    width: 350px;
    height: 45px;  
    border-radius: 10px;
    border: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: 'row';
    align-items: center;
    justify-content: center;
    align-self: center;
    color: black;
    font-size: 18px;
    margin-top: 120px;
    font-weight: 600;
    text-decoration: none;
  }
  
  .section-1 > a:not([disabled]):active {
    background-color: #94C62D;
    transform: translateY(2px);
  }
  
  .fab {
    position: fixed;
    right: 20px;
    bottom: 20px
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  
  
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  
  .logo {
    width: 150px;
  }
  
  .header-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: white;
    text-align: center;
  }
  
  .header-desc {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: white;
    margin-top: -20px;
    text-align: center;
  }
  
  
  .section-0 {
    display: flex;
    flex-direction: column;
    width: 400px;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin-top: 50px;
    background: #F5F0D2;
    padding: 20px;
    border-radius: 12px;
  }
  
  .section-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin-top: 130px;
  }
  
  .onboarding-form {
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  
  .input-label {
    font-style: normal;
    font-weight: bolder;
    font-size: 12px;
  }
  
  .input-field-err-msg {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #FF0000;
    margin-left: 20px;
  }
  
  .input-span {
    margin-top: 10px;
  }
  
  .input-field {
    width: 100%;
    height: 100%;
    border: 0px;  
    background: rgba(0, 0, 0, 0.0);
    font-size: 15px;
    font-weight: 400;
    /* background: #FAFAFA; */
    /* background-color: #016AEC; */
  }
  
  .input-field-box {
    width: 360px;
    height: 40px;
    background: #F5F0D2;
    /* background-color: blue; */
    padding: 3px 10px;;
    border-radius: 6px;
    border: 1px solid grey;
  }
  
  input:focus {
    outline: none;
    /* Add any other styles you want for the focused state */
  }
  
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    /* Add any other styles you want for autofill */
  }
  
  select:focus {
    outline: none;
    /* Add any other styles you want for the focused state */
  }
  
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    /* Add any other styles you want for autofill */
  }
  
  .input-field-2 {
    width: 100%;
    height: 100%;
    border: 0px;
    outline: none;
    background: rgba(0, 0, 0, 0.0);
  }
  
  .input-field-2:focus {
    outline: none;
  }
  
  .input-field-err {
    border: 1px solid #FF0000;
    width: 360px;
    height: 40px;
    background: #FAFAFA;
    padding: 0px 10px;
    margin-right: 50px; 
  }
  
  .input-field-box-err {
    border: 1px solid #FF0000;
    width: 360px;
    height: 40px;
    background: #F5F0D2;
    /* background-color: blue; */
    padding: 3px 10px;;
    border-radius: 6px;
  }
  
  .street-suggestions {
    background: #F1ECC7;
    padding: 0px 10px 10px 10px;
    height: 140px; /* Set the desired height for your container */  
    width: 360px;
    overflow: hidden; /* Hide any content that overflows the container */
    position: absolute;
    z-index: 1000;
  }

  .street-suggestions-contents {
    overflow: scroll;
    height: 100%;
  }
  
  .suggestion-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
    background: #F5F0D2;
    z-index: 100;
  }
  
  .suggestion-item p {
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  /* Apply the overlay to the target element */
  .suggestion-item:hover {
    background-color: #E8E8E8;
  }
  
  .is-existing-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 360px;
    margin-top: 10px;
  }
  
  .is-existing-group > p {
    font-size: 13px;
  }
  
  .primary-btn {
    
    background-color: #B4EA44;
    width: 350px;
    height: 45px;  
    border-radius: 10px;
    border: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: 'row';
    align-items: center;
    justify-content: center;
    align-self: center;
    color: black;
    font-size: 18px;
    /* font-family: Sora Variable; */
    font-weight: 600;
  }
  
  .primary-btn:not([disabled]):active {
    background-color: #94C62D;
    transform: translateY(2px);
  }
  
  button[disabled] {
    opacity: 0.9;
    cursor: not-allowed;
  }
  
  .primary-btn img {
    width: 20px;
    height: 15px;
    margin-left: 16px;
  }
  
  .secondary-btn {
    
    background-color: #F3F3F3;
    width: 180px;
    height: 45px;  
    background: #F3F3F3;
    border-radius: 45px;
    border: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: 'row';
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 18px;
    /* font-family: Sora Variable; */
    font-weight: 500;
    margin-right: 35px;
  }
  
  .secondary-btn img {
    width: 20px;
    height: 15px;
    margin-right: 16px;
  }
  
  .btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  
  }
  
  .banner-1 {
    height: 500px
  }
  
  .form-summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .summary-item {
    width: 540px;
  }
  
  .summary-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .summary-item-header-title {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }
  
  .summary-item-header-btn {
    background-color: rgba(0, 0, 0, 0.0);
    border: 0px;
    color: #016AEC;
    text-decoration: underline;
    cursor: pointer;
    /* font-family: Sora Variable; */
    font-size: 16px;
  }
  
  .summary-item-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    width: 540px;
    max-width: 540px;
    /* height: 350px; */
    padding: 25px;
    box-sizing: border-box;
  }
  
  .summary-item-group {
    display: flex;
    flex-direction: row;  
    width: 480px,
  }
  
  .summary-item-group-child {
    flex-basis: 50%;
  }
  
  .summary-item-group-child-label {
    font-size: 12px;
    color: #000000;
  }
  
  .summary-item-group-child-value {
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    margin-top: -4px;
  }
  
  .meter-integration-section {
    margin-top: 50px;
  }
  
  .meter-integration-title {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }
  
  .meter-integration-sub-title {
    font-weight: 600;
    /* font-family: Sora Variable; */
    font-style: normal;
    font-size: 14px;
    color: #454545;
    margin-top: -4px;
    width: 600px;
    margin-bottom: 20px;
  }
  
  .meter-integration-section img {
    /* height: 328px; */
    width: 1377px;
    margin-top: -30px;
  }
  
  .meter-selection-group {
    display: flex;
    flex-direction: row;
    align-items: center;  
    margin-top: -10px;
  }
  
  .meter-selection-group > p {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-left: 10px;
    color: #000;
  }
  
  .modal-contents {
    background: #F5F0D2;
    border-radius: 15px;
    width: 500px;
    /* height: 380px; */
    margin-top: 250px;
    padding: 20px;
    box-sizing: border-box;
    z-index: 999999999,
  }
  
  .close-btn-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .close-btn-group img {
    cursor: pointer;
    width: 24px;
  }
  
  .modal-title {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    margin-left: 20px;
  }
  
  .modal-sub-title {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: -10px;
  }
  
  textarea {
    resize: none;
  }
  
  .modal-btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .modal-btn-group-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 80px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  /* .modal-btn-group button {
    border: 0px;
    border-radius: 40px;
    height: 40px;
    min-width: 120px;
    padding: 0px 15px;
  } */
  
  .modal-secondary-btn {
    background: #D5D1B7;
    border: 0px;
    border-radius: 40px;
    height: 40px;
    min-width: 120px;
    padding: 0px 15px;
    cursor: pointer;
    font-weight: 600;
  }
  
  .modal-primary-btn {
    background-color: #B4EA44;
    color: black;
    border: 0px;
    border-radius: 40px;
    height: 40px;
    min-width: 120px;
    font-weight: 600;
    padding: 0px 15px;
    cursor: pointer;
  }
  
  .modal-primary-btn:active {
    background-color: #94C62D;
    transform: translateY(2px);
  } 
  
  .terms-section {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 360px;
  }
  
  .terms-section p {
    color: #000;
    font-size: 12px;
    margin-left: 10px;
    font-weight: 500;
  }
  
  
  .section-1 {
    display: flex;
    flex-direction: column;
    margin-top: 130px;
    width: 650px;
  }
  
  .success-icon {
    width: 180px;
  }
  
  .section-1 > p {
    color: #FFF;
    text-align: center;
    font-family: 'Sora';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 80px;
  }
  
  .section-1 > a {
    
    background-color: #B4EA44;
    width: 350px;
    height: 45px;  
    border-radius: 10px;
    border: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: 'row';
    align-items: center;
    justify-content: center;
    align-self: center;
    color: black;
    font-size: 18px;
    margin-top: 120px;
    font-weight: 600;
    text-decoration: none;
  }
  
  .section-1 > a:not([disabled]):active {
    background-color: #94C62D;
    transform: translateY(2px);
  }
  
  .fab {
    position: fixed;
    right: 20px;
    bottom: 20px
  }
}

@media screen and (max-width: 480px)  {
 
  
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  
  .logo {
    width: 150px;
  }
  
  .header-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: white;
    text-align: center;
  }
  
  .header-desc {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: white;
    margin-top: -10px;
    text-align: center;
  }
  
  
  .section-0 {
    display: flex;
    flex-direction: column;
    width: 330px;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin-top: 50px;
    background: #F5F0D2;
    padding: 20px;
    border-radius: 12px;
  }
  
  .section-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin-top: 130px;
  }
  
  .onboarding-form {
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  
  .input-label {
    font-style: normal;
    font-weight: bolder;
    font-size: 12px;
  }
  
  .input-field-err-msg {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #FF0000;
    margin-left: 20px;
  }
  
  .input-span {
    margin-top: 10px;
  }
  
  .input-field {
    width: 100%;
    height: 100%;
    border: 0px;  
    background: rgba(0, 0, 0, 0.0);
    font-size: 15px;
    font-weight: 400;
    /* background: #FAFAFA; */
    /* background-color: #016AEC; */
  }
  
  .input-field-box {
    width: 280px;
    height: 40px;
    background: #F5F0D2;
    /* background-color: blue; */
    padding: 3px 10px;;
    border-radius: 6px;
    border: 1px solid grey;
  }
  
  input:focus {
    outline: none;
    /* Add any other styles you want for the focused state */
  }
  
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    /* Add any other styles you want for autofill */
  }
  
  /* select:focus {
    outline: none;
    /* Add any other styles you want for the focused state */
  }

  
  .input-field-2 {
    width: 100%;
    height: 100%;
    border: 0px;
    outline: none;
    background: rgba(0, 0, 0, 0.0);
  }
  
  .input-field-2:focus {
    outline: none;
  }
  
  .input-field-err {
    border: 1px solid #FF0000;
    width: 360px;
    height: 40px;
    background: #FAFAFA;
    padding: 0px 10px;
    margin-right: 50px; 
  }
  
  .input-field-box-err {
    border: 1px solid #FF0000;
    width: 360px;
    height: 40px;
    background: #F5F0D2;
    /* background-color: blue; */
    padding: 3px 10px;;
    border-radius: 6px;
  }
  
  .street-suggestions {
    background: #F1ECC7;
    padding: 0px 10px 10px 10px;
    height: 140px; /* Set the desired height for your container */  
    width: 360px;
    position: absolute;
    z-index: 1000;
    overflow: hidden; /* Hide any content that overflows the container */
  }
  
  .street-suggestions-contents {
    height: 100%;
    overflow-y: scroll; /* Enable vertical scrolling for the content */
  }
  
  
  .suggestion-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
    background: #F5F0D2;
    z-index: 100;
  }
  
  .suggestion-item p {
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  /* Apply the overlay to the target element */
  .suggestion-item:hover {
    background-color: #E8E8E8;
  }
  
  .is-existing-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 280px;
    margin-top: 10px;
  }
  
  .is-existing-group > p {
    font-size: 13px;
  }
  
  .primary-btn {
    
    background-color: #B4EA44;
    width: 280px;
    height: 45px;  
    border-radius: 10px;
    border: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: 'row';
    align-items: center;
    justify-content: center;
    align-self: center;
    color: black;
    font-size: 18px;
    /* font-family: Sora Variable; */
    font-weight: 600;
  }
  
  .primary-btn:not([disabled]):active {
    background-color: #94C62D;
    transform: translateY(2px);
  }
  
  button[disabled] {
    opacity: 0.9;
    cursor: not-allowed;
  }
  
  .primary-btn img {
    width: 20px;
    height: 15px;
    margin-left: 16px;
  }
  
  .secondary-btn {
    
    background-color: #F3F3F3;
    width: 180px;
    height: 45px;  
    background: #F3F3F3;
    border-radius: 45px;
    border: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: 'row';
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 18px;
    /* font-family: Sora Variable; */
    font-weight: 500;
    margin-right: 35px;
  }
  
  .secondary-btn img {
    width: 20px;
    height: 15px;
    margin-right: 16px;
  }
  
  .btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  
  }
  
  .banner-1 {
    height: 500px
  }
  
  .form-summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .summary-item {
    width: 540px;
  }
  
  .summary-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .summary-item-header-title {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }
  
  .summary-item-header-btn {
    background-color: rgba(0, 0, 0, 0.0);
    border: 0px;
    color: #016AEC;
    text-decoration: underline;
    cursor: pointer;
    /* font-family: Sora Variable; */
    font-size: 16px;
  }
  
  .summary-item-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    width: 540px;
    max-width: 540px;
    /* height: 350px; */
    padding: 25px;
    box-sizing: border-box;
  }
  
  .summary-item-group {
    display: flex;
    flex-direction: row;  
    width: 480px,
  }
  
  .summary-item-group-child {
    flex-basis: 50%;
  }
  
  .summary-item-group-child-label {
    font-size: 12px;
    color: #000000;
  }
  
  .summary-item-group-child-value {
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    margin-top: -4px;
  }
  
  .meter-integration-section {
    margin-top: 50px;
  }
  
  .meter-integration-title {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }
  
  .meter-integration-sub-title {
    font-weight: 600;
    /* font-family: Sora Variable; */
    font-style: normal;
    font-size: 14px;
    color: #454545;
    margin-top: -4px;
    width: 600px;
    margin-bottom: 20px;
  }
  
  .meter-integration-section img {
    /* height: 328px; */
    width: 1377px;
    margin-top: -30px;
  }
  
  .meter-selection-group {
    display: flex;
    flex-direction: row;
    align-items: center;  
    margin-top: -10px;
  }
  
  .meter-selection-group > p {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-left: 10px;
    color: #000;
  }
  
  .modal-contents {
    background: #FFFFFF;
    border-radius: 15px;
    width: 500px;
    /* height: 380px; */
    margin-top: 250px;
    padding: 20px;
    box-sizing: border-box;
    z-index: 999999999,
  }
  
  .close-btn-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .close-btn-group img {
    cursor: pointer;
    width: 24px;
  }
  
  .modal-title {
    font-family: Sora;
    font-style: normal;
    font-weight: semibold;
    font-size: 24px;
    margin-left: 20px;
    color:#212121;
  }
  
  .modal-sub-title {
    /* font-family: Sora Variable; */
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: -10px;
  }
  
  textarea {
    resize: none;
  }
  
  .modal-btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .modal-btn-group-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 80px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  /* .modal-btn-group button {
    border: 0px;
    border-radius: 40px;
    height: 40px;
    min-width: 120px;
    padding: 0px 15px;
  } */
  
  .modal-secondary-btn {
    background: #D5D1B7;
    border: 0px;
    border-radius: 40px;
    height: 40px;
    min-width: 120px;
    padding: 0px 15px;
    font-weight: 600;
    cursor: pointer;
  }
  
  .modal-primary-btn {
    background-color: #B4EA44;
    color: black;
    border: 0px;
    border-radius: 40px;
    height: 40px;
    min-width: 120px;
    font-weight: 600;
    padding: 0px 15px;
    cursor: pointer;
  }
  
  .modal-primary-btn:active {
    background-color: #94C62D;
    transform: translateY(2px);
  } 
  
  .terms-section {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 280px;
  }
  
  .terms-section p {
    color: #000;
    font-size: 12px;
    margin-left: 10px;
    font-weight: 500;
  }
  
  
  .section-1 {
    display: flex;
    flex-direction: column;
    margin-top: 130px;
    width: 650px;
  }
  
  .success-icon {
    width: 180px;
  }
  
  .section-1 > p {
    color: #FFF;
    text-align: center;
    font-family: 'Sora';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 80px;
  }
  
  .section-1 > a {
    
    background-color: #B4EA44;
    width: 350px;
    height: 45px;  
    border-radius: 10px;
    border: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: 'row';
    align-items: center;
    justify-content: center;
    align-self: center;
    color: black;
    font-size: 18px;
    margin-top: 120px;
    font-weight: 600;
    text-decoration: none;
  }
  
  .section-1 > a:not([disabled]):active {
    background-color: #94C62D;
    transform: translateY(2px);
  }
  
  .fab {
    position: fixed;
    right: 20px;
    bottom: 20px
  }




.scrolling-container {
  background: #F1ECC7;
  padding: 0px 10px 10px 10px;
  height: 140px;  
  width: 360px;
  overflow: hidden; 
}

.scrollable-content {
  height: 100%;
  overflow-y: scroll; 
}