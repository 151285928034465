@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.customPing {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
@keyframes ping {
  75%,
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .center {
    @apply flex items-center justify-center;
  }

  .lift {
    @apply shadow-[10px_40px_100px_0px_rgba(0,_0,_0,_0.08)];
  }
  .formLabel {
    @apply text-xs md:text-sm font-inter font-medium mb-2.5 block;
  }

  /* .formInput {
    @apply border border-new-grey-100 focus:border-new-black-1000 focus-within:border-new-black-1000 placeholder:text-grey-600 p-4 w-full rounded-lg text-sm lg:text-base font-inter font-medium;
  } */
  .formInput {
    @apply border border-transparent focus:border-grey-600 focus-within:border-new-black-1000 placeholder:text-grey-600 p-4 w-full rounded-lg text-sm lg:text-base font-inter font-medium bg-grey-150;
  }
  .formInputUpdate {
    @apply border border-transparent focus:border-grey-600 focus-within:border-new-black-1000 placeholder:text-grey-600 p-4 w-full rounded-lg text-sm lg:text-base font-inter font-medium bg-grey-150;
  }
  .modalSubtitle {
    @apply text-sm font-inter leading-[20px] text-[#212121] mt-5 lg:text-base;
  }
  .modalTitle {
    @apply font-sora font-semibold text-[#212121] text-xl md:text-2xl;
  }

  .secondaryButton {
    @apply bg-white border border-black rounded-lg py-4 text-sm lg:text-base font-sora font-semibold text-green-500;
  }
  .primaryButton {
    @apply bg-light-green-500 py-4 text-sm lg:text-base rounded-lg font-sora font-semibold text-green-500 text-center;
  }
  .buttonGroup {
    @apply flex flex-col-reverse gap-3 mt-[50px] md:flex-row;
  }
  .textHeader {
    @apply text-black text-base lg:text-lg xl:text-[32px] font-bold font-inter leading-[30px] xl:leading-[48px];
  }
  .textBody {
    @apply text-black text-sm leading-[26px] md:text-base lg:text-lg font-normal font-inter lg:leading-[30px];
  }
  .menuDark {
    @apply p-5 px-3 lg:text-sm xl:text-[16px]  xl:p-5 text-white hover:text-light-green-500 transition-all duration-300 flex items-center gap-2.5 font-medium;
  }
  .innerShadow {
    @apply shadow-[4px_4px_40px_0px_rgba(151,_71,_255,_0.10)_inset,_-4px_-4px_40px_0px_rgba(151,_71,_255,_0.10)_inset];
  }
  .dropDownMenu {
    @apply font-inter font-medium px-5 block whitespace-nowrap p-2 hover:bg-light-green-500 rounded-[6px];
  }
  .keyFeatures {
    @apply flex flex-col md:flex-row-reverse bg-light-purple-50 border border-new-grey-100 w-full lg:rounded-[20px] lg:overflow-hidden md:h-[50vh] portrait:lg:h-[40vh]   xl:h-[560px] 2xl:h-[680px];
  }
}
