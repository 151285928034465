.custom-date-range-picker-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex: 1,
  }
  
/* Hide on mobile devices */
@media (max-width: 767px) {
    .rdrDefinedRangesWrapper {
      display: none;
    }
  }
  
  .custom-date-range-picker-contents {
    /* width: 680px; */
    /* height: 850px; */  
    background-color: #FFF;
    border-radius: 24px;
    align-self: center;
    justify-self: center;
  }
  
  .custom-date-range-picker {
    margin: 25px 15px;
  }
  
  .custom-date-range-picker-contents-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #CBF07F;
    height: 80px;
    padding: 0px 24px;
  }
  
  .custom-date-range-picker-contents-header p {
    color: var(--neutral-black-02-d-2-d-2-d, #2D2D2D);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px; /* 120.833% */
    letter-spacing: -0.48px;
  }
  
  .custom-date-range-picker-close-btn {
    padding: 10px;
    cursor: pointer;
    
  }
  
  .custom-date-range-picker-close-icon {
    width: 32px;
    height: 32px;
    color: black;
  }
  
  .custom-date-range-picker-ctas {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px;
  }
  
  .custom-date-range-picker-close-btn {
    padding: 0px 10px;
    color: var(--Black-1, #1A1A1A);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    cursor: pointer;
  }
  
  .custom-date-range-picker-done-btn {
    display: flex;
    padding: 8px 35px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 100px;
    background: #CBF07F;
    color: black;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-left: 20px;
    cursor: pointer;
  }